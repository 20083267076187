import React from 'react'
import Layout from '../../components/Layout'
import GradientContainer from '../../components/GradientContainer'
import Heading from '../../components/Heading'
import Highlight from '../../components/Highlight'
import {Helmet} from 'react-helmet'
import awardis from './../../images/awardis.jpg'

export default function Impressum() {
  return (
    <Layout>
      <Helmet>
        <title>Impressum</title>
      </Helmet>
      <GradientContainer containerProps={{
        className: 'flex flex-col space-y-16 md:space-y-32'
      }}>
        <Heading
          as="span"
          className="text-3xl lg:text-4xl font-semibold"
          dotsContainerClassName="-top-20"
        >
          <Highlight as="h1">Impressum</Highlight>
        </Heading>

        <div>
          <div className="mt-8 mb-4">
            <Highlight as="h2">Angaben gem&auml;&szlig; &sect; 5 TMG</Highlight>
          </div>
          <p>
            Marco`s Fahrschule<br />
            Inhaber: Marco Waider<br />
            Kaiserstraße 9<br />
            36088 Hünfeld
          </p>

          <div className="mt-8 mb-4">
            <Highlight as="h2">Kontakt</Highlight>
          </div>
          <p>Telefon: 06652 - 74 77 77<br />
            E-Mail: Marco@marcos-fahrschule.de</p>

          <div className="mt-8 mb-4">
            <Highlight as="h2">Steuernummer</Highlight>
          </div>
          <p>01887931506</p>

          <div className="mt-8 mb-4">
            <Highlight as="h2">Aufsichtsbehörde</Highlight>
            <p>
              Regierungspräsidium Kassel <br />
              Steinweg 6<br />
              34117 Kassel
            </p>
          </div>

          <div className="mt-8 mb-4">
            <Highlight as="h2">Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</Highlight>
          </div>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

          <div className="mt-16 mb-4">
            <Highlight as="h2">Erstellung dieser Webseite</Highlight>
          </div>
          <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8">
            <img src={awardis} width={200} alt="awardis GmbH" />
            <p className="flex flex-col justify-center space-y-2">
              <span>awardis GmbH - <span className="text-[#C64543]">Digital Agency</span></span>
              <a href="https://award.is" target="_blank" className="text-[#C64543] hover:text-[#FE5856]">www.award.is - Softwareentwicklung Fulda</a>
            </p>
          </div>
        </div>
      </GradientContainer>
    </Layout>
  )
}
